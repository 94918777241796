@use "../../breakpoints" as *;
@import url("../../_variables.scss");
// @import url("../../_breakpoints.scss");
.Landing{
  width: 100%;
  height: calc(100vh - 80px);
  background-image: url("https://res.cloudinary.com/dlommecfr/image/upload/v1715424755/bg_fkcqk5.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  background-attachment: fixed;
  background-clip: border-box;
  color: white;
  display: flex;
  .container1{
    position: relative;
    display: block;
    width: 85%;
    height: auto;
    margin: 150px auto;
    text-align: center;
  }
  .Writer{
    margin-top: -100px;
    color: var(--mainColor);
    font-size: 50px;
    font-weight: bold;
    opacity: 0.8;
    @include breakpoint(745px){
      font-size: 42px;
    }
    @include sm(){
      font-size: 32px;
    }
  }
  .Text{
    margin-top: 20px;
    width: 100%;
    width: 400px;
    display: block;
    margin: 20px auto;
    line-height: 1.8;
    font-size: 20px;
    opacity: 0.8;
    @include sm(){
      font-size: 16px;
      line-height: 1.5;
      width: 300px;
    }
  }
}

.mainHeading{
  text-align: center;
  .Heading1{
    color: var(--mainColor);
    font-weight: bold;
    font-size: 30px;
    text-transform: capitalize;
  }
  .Heading2{
    font-weight: bold;
    font-size: 32px;
    margin-bottom: 32px;
  }
}

.Services{
  .container1{
    .mainHeading{
      text-align: center;
      .Heading1{
        color: var(--mainColor);
        font-weight: bold;
        font-size: 30px;
        text-transform: capitalize;
      }
      .Heading2{
        font-weight: bold;
        font-size: 32px;
        margin-bottom: 32px;
      }
    }
    .Service{
      transition: 0.2s;
      border: 1px solid #777;
      border-radius: 24px;
      display: flex;
      margin-bottom: 64px;
      @include breakpoint(900px){
        flex-direction: column;
      }
      &:hover{
        .serviceHeading{
          color: var(--mainColor);
        }
        border: 2px solid var(--mainColor);
        box-shadow: 10px 10px 31px -12px rgba(0,0,0,0.75);

      }
      .image{
        width: 40%;
        height: 400px;
        
        @include breakpoint(900px){
          width: 100%;
          height: 450px;
        }
        @include md(){
          height: 300px;
        }
        @include sm(){
          height: 200px;
        }
        img{
          height: 100%;
          width: 100%;
          border-radius: 24px;
          object-fit: cover;
        }
      }
      .content{
        width: 55%;
        padding: 20px;
        text-align: left;
        @include breakpoint(900px){
          width: 100%;
          text-align: center;
        }
        .serviceHeading{
          font-size: 40px;
          margin-bottom: 32px;
        }
        .Text{
          line-height: 1.8;
          font-size: 18px;
          color: #777;
          margin-bottom: 32px;
        }
        .Link{
          padding: 8px 16px;
          opacity: 0.7;
          
        }
      }
    }
  }
}

%inputArea{
  display: inline-block;
  border-radius: 16px;
  width: 50%;
  padding: 8px;
  @include md(){
    width: 100%;
  }
}
.Contact{
  .container1{
    main{
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      gap: 24px;
      #userName{
        @extend %inputArea;
        
      }
      #userMail{
        @extend %inputArea;
      }
      #userMessage{
        @extend %inputArea;
        height: 200px;
      }
      .mainBtn{
        display: inline-block;
        width: 50% !important;
      }
    }
  }
}