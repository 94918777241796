@import url("../../_variables.scss");
.Landing {
  width: 100%;
  height: calc(100vh - 80px);
  background-image: url("https://res.cloudinary.com/dlommecfr/image/upload/v1715424755/bg_fkcqk5.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  background-attachment: fixed;
  background-clip: border-box;
  color: white;
  display: flex;
}
.Landing .container1 {
  position: relative;
  display: block;
  width: 85%;
  height: auto;
  margin: 150px auto;
  text-align: center;
}
.Landing .Writer {
  margin-top: -100px;
  color: var(--mainColor);
  font-size: 50px;
  font-weight: bold;
  opacity: 0.8;
}
@media (max-width: 745px) {
  .Landing .Writer {
    font-size: 42px;
  }
}
@media (max-width: 480px) {
  .Landing .Writer {
    font-size: 32px;
  }
}
.Landing .Text {
  margin-top: 20px;
  width: 100%;
  width: 400px;
  display: block;
  margin: 20px auto;
  line-height: 1.8;
  font-size: 20px;
  opacity: 0.8;
}
@media (max-width: 480px) {
  .Landing .Text {
    font-size: 16px;
    line-height: 1.5;
    width: 300px;
  }
}

.mainHeading {
  text-align: center;
}
.mainHeading .Heading1 {
  color: var(--mainColor);
  font-weight: bold;
  font-size: 30px;
  text-transform: capitalize;
}
.mainHeading .Heading2 {
  font-weight: bold;
  font-size: 32px;
  margin-bottom: 32px;
}

.Services .container1 .mainHeading {
  text-align: center;
}
.Services .container1 .mainHeading .Heading1 {
  color: var(--mainColor);
  font-weight: bold;
  font-size: 30px;
  text-transform: capitalize;
}
.Services .container1 .mainHeading .Heading2 {
  font-weight: bold;
  font-size: 32px;
  margin-bottom: 32px;
}
.Services .container1 .Service {
  transition: 0.2s;
  border: 1px solid #777;
  border-radius: 24px;
  display: flex;
  margin-bottom: 64px;
}
@media (max-width: 900px) {
  .Services .container1 .Service {
    flex-direction: column;
  }
}
.Services .container1 .Service:hover {
  border: 2px solid var(--mainColor);
  box-shadow: 10px 10px 31px -12px rgba(0, 0, 0, 0.75);
}
.Services .container1 .Service:hover .serviceHeading {
  color: var(--mainColor);
}
.Services .container1 .Service .image {
  width: 40%;
  height: 400px;
}
@media (max-width: 900px) {
  .Services .container1 .Service .image {
    width: 100%;
    height: 450px;
  }
}
@media (max-width: 720px) {
  .Services .container1 .Service .image {
    height: 300px;
  }
}
@media (max-width: 480px) {
  .Services .container1 .Service .image {
    height: 200px;
  }
}
.Services .container1 .Service .image img {
  height: 100%;
  width: 100%;
  border-radius: 24px;
  -o-object-fit: cover;
     object-fit: cover;
}
.Services .container1 .Service .content {
  width: 55%;
  padding: 20px;
  text-align: left;
}
@media (max-width: 900px) {
  .Services .container1 .Service .content {
    width: 100%;
    text-align: center;
  }
}
.Services .container1 .Service .content .serviceHeading {
  font-size: 40px;
  margin-bottom: 32px;
}
.Services .container1 .Service .content .Text {
  line-height: 1.8;
  font-size: 18px;
  color: #777;
  margin-bottom: 32px;
}
.Services .container1 .Service .content .Link {
  padding: 8px 16px;
  opacity: 0.7;
}

.Contact .container1 main #userMessage, .Contact .container1 main #userMail, .Contact .container1 main #userName {
  display: inline-block;
  border-radius: 16px;
  width: 50%;
  padding: 8px;
}
@media (max-width: 720px) {
  .Contact .container1 main #userMessage, .Contact .container1 main #userMail, .Contact .container1 main #userName {
    width: 100%;
  }
}

.Contact .container1 main {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 24px;
}
.Contact .container1 main #userMessage {
  height: 200px;
}
.Contact .container1 main .mainBtn {
  display: inline-block;
  width: 50% !important;
}/*# sourceMappingURL=Home.css.map */