@import url("./_variables.scss");
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

body {
  margin: 0;
}

::-webkit-scrollbar {
  background-color: transparent;
  width: 7px;
}

::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background: linear-gradient(transparent, var(--mainColor), transparent);
}

.container1 {
  width: 85%;
  height: auto;
  margin: auto;
}

a {
  text-decoration: none;
}

.MainBtn {
  padding: 16px;
  border-radius: 16px;
  border: none;
  cursor: pointer;
  text-align: center;
  background-color: var(--mainColor);
  color: white;
  font-size: 20px;
  font-weight: bold;
  opacity: 0.8;
}/*# sourceMappingURL=index.css.map */